import { settingService } from "@/services";

export const settingCtrl = {
  getMethodPay: async (orderType) => {
    const { data } = await settingService.getSetting();
    const { domicile_payments, local_payments, pickup_payments } = data.data;
    const paymentSetting = {
      LOCAL: local_payments,
      AT_HOME: domicile_payments,
      TO_PICK_UP: pickup_payments,
    };
    return paymentSetting[orderType];
  },
  getPriceDomicileService: async () => {
    const { data } = await settingService.getGlobalSetting();

    return data.data;
  },
};
