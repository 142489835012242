import { axiosInstance } from "@/core";
import { addAccessTokenToHeaders, buildUrl } from "@/helpers";

const config = addAccessTokenToHeaders;

export const orderService = {
  getOrders: async () =>
    await axiosInstance.get(buildUrl(`histories/orders_by_user`), config()),
  getOrderById: async (requestId) =>
    await axiosInstance.get(buildUrl(`requests/${requestId}`), config()),
};
